import styled from "styled-components"
import bp from "../styles/vars/bp"
import colors from "../styles/vars/colors"
import fonts from "../styles/vars/fonts"
import LN from "../../images/svg/article_ln.svg";
import TW from "../../images/svg/article_tw.svg";
import LNHover from "../../images/svg/article_ln_hover.svg";
import TWHover from "../../images/svg/article_tw_hover.svg";
import {ContentContainer } from "../styles/grid"
import {Subcopy, BlockTitle} from "../styles/typography/typography"

export const ArticleBlock = styled(ContentContainer)`
    border-bottom: 3px ${colors["casestudyline"]} solid;
    overflow: hidden;

    .social--stick{
        margin-bottom: 170px;
    }

    .tags{
        border-top: 1px ${colors["casestudyline"]} solid;
        .tags__list{
            margin: 25px 0;
            li{
                list-style: none;
                margin: 0;
                padding-right:23px;
            }
        }
    }
    
    .content {
        em, h3, .hide{
            display: none;
        }
        h4{
            font-family: ${fonts["ff-p"]};
            font-size: 20px;
            line-height: 32px;
            margin: 40px 0 10px 0;
            font-weight: 400;
        }

        p{
            font-family: ${fonts["ff-p"]};
            font-size: ${fonts["p-l"]}px;
            line-height: ${fonts["p-lh"]}px;
            color: ${colors["darkgrey"]};
            text-align: left;
            margin-bottom: 15px;
            cursor: default;
            font-display: fallback;
        }

        blockquote {
            font-family: ${fonts["ff-quotes"]};
            color: ${colors["orange"]};
            text-align: center;
            font-size: calc( (${fonts["h2-m"]}px + (${fonts["h2-l"]} - ${fonts["h2-m"]}) * ((100vw - ${bp["bp-tablet"]}) / (1440 - 1024)) ));
            line-height: calc( (${fonts["h2-lh-m"]}px + (${fonts["h2-lh-l"]} - ${fonts["h2-lh-m"]}) * ((100vw - ${bp["bp-tablet"]}) / (1440 - 1024)) ));
            margin: 100px 0;

            @media (max-width: ${bp["bp-mobile"]}) {
                font-size: ${fonts["h2-s"]}px;
                line-height: ${fonts["h2-lh-s"]}px;
                margin: 57px 0;
            }

            @media (max-width: ${bp["bp-mobile-s"]}) {
                font-size: ${fonts["h2-ss"]}px;
                line-height: ${fonts["h2-lh-ss"]}px;
            }
        }

        img{
            margin-bottom: 0;
            width: 100%;
        }

    }
    
    @media (max-width: ${bp["bp-tablet"]}) {
        .main{
            flex-direction: column;
        }
        .social{
            flex-direction: column !important;
        }
    }

`

export const Socials = styled(ContentContainer)`
    &.desktop{
        position: fixed;
        right: 5%;
        top: 45%;
        width: 10%;
    }
    &.mobile{
        display: none;
        justify-content: flex-end;
        align-items: flex-end;
        margin-top: 1em;
        .socials__icons{
            flex-direction: column;
            margin-bottom: 15px;
        }
    }
    .socials__icons{
        margin-bottom: 30px;
        .socials__icons__button{
            width: 100%;
            height: 100%;
            &:focus{
                outline: none
            }
        }
    }

    hr{
        width: 48px;
    }

    a{
        background-size: contain;
        background-repeat: no-repeat;
        background-size: contain;
        width: 47px;
        height: 47px;
        margin: 12px 0;
        font-weight: 100;
        line-height: 28px;
        cursor: pointer;
        @media (hover: hover) {
            &:hover{
                color: ${colors["orange"]};
            }
        }
    }
    @media (max-width: ${bp["bp-tablet"]}) {
        .socials__icons{
            flex-direction: row;
            a{
                margin-right: 30px;
            }
        }
        &.desktop{
            display: none;
        }
        &.mobile{
            display: flex;
        }
    }
`

export const Author = styled(BlockTitle)`
    color: ${colors["orange"]};
    margin-top: 15px;
    &:first-child{
        margin-top: 30px;
    }
`

export const Subtitle = styled(Subcopy)`
    font-size: 20px;
    line-height: 32px;
    margin-top: 10px;
`

export const Kicker = styled(BlockTitle)`
    border-bottom: 1px ${colors["casestudyline"]} solid;
    border-top: 1px ${colors["casestudyline"]} solid;
    padding: 15px 0;
    width: 70%;
    margin-bottom: 30px;
    @media (max-width: ${bp["bp-tablet"]}) {
        width: 100%;
    }
`

const SocialIcon = styled.div`
    height: 30px;
    width: 95px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
`

export const SocialLN = styled(SocialIcon)`
    background-image: url(${LN});
    margin-bottom: 20px;
    @media (hover: hover) {
      &:hover{
          background-image: url(${LNHover});
      }
    }
`

export const SocialTW = styled(SocialIcon)`
    background-image: url(${TW});
    @media (hover: hover) {
      &:hover{
          background-image: url(${TWHover});
      }
    }
`