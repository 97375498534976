

import React, { useState } from 'react';
import {Column} from "../styles/grid"
import {Subcopy} from "../styles/typography/typography"
import {SocialTW, SocialLN, Socials} from "./articleStyle"
import {LinkedinShareButton, TwitterShareButton} from "react-share"

const ShareBar = ({title="title", link="/", className}) => {
    const [shareUrl, setShareUrl] = useState('https://cosignprojects.com/ideas/')

return (
        <Socials className={className}>
            <Column pd_m={'0 0 50px 0'}>
                <Column className="socials__icons">
                    <SocialLN>
                        <LinkedinShareButton url={shareUrl} className="socials__icons__button" title={title} />
                    </SocialLN> 
                    <SocialTW>
                        <TwitterShareButton url={shareUrl} className="socials__icons__button" title={title}/>
                    </SocialTW>
                </Column>
                <Column className="readmore">
                    <Subcopy><a href={link}  target="_blank">read on Medium</a></Subcopy>
                </Column>
            </Column>
        </Socials>   
    )

}

export default ShareBar