import React, { useState, useEffect } from 'react';

import Layout from "../components/layout"
import SEO from "../components/seo"
import Articles from "../components/articles/articles"
import ShareBar from "../components/articles/shareBar"

const SecondPage = () => {
  const [mediumPosts, setMediumPosts] = useState([]);
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [currentArticle, setCurrentArticle] = useState(0);
  const mediumRSSFeedLink = 'https://medium.com/feed/@wasinfall'

  const inScreen = (id, shown) =>{
    setCurrentArticle(shown ? id : currentArticle)
  } 
  
  useEffect(()=>{
    fetch(urlForFeedToJson(mediumRSSFeedLink))
      .then(response => {
        if (!response.ok) {
          throw Error("Network request failed")
        }
        return response
      })
      .then(data => data.json())
      .then(data => {   
        const dataItems = data.items;
        //const posts = dataItems.filter(item => item.categories.length > 0);
        setRequestSuccess(true);
        setMediumPosts([dataItems[1],dataItems[1]]);
      }, () => {
        setRequestSuccess(false);
      })
  },[]);

  const urlForFeedToJson = feed => `https://api.rss2json.com/v1/api.json?rss_url=${feed}`;
  const keyCategories = ['javascript', 'front-end-development', 'responsive-web-design'];
  const contains = (keyCategories, categories) => {
    const [category1, category2, category3] = keyCategories
    return categories.indexOf(category1) > -1 || categories.indexOf(category2) > -1 || categories.indexOf(category3) > -1
  }

  return (
    <Layout>
      <SEO title="ideas" />
      {/* <div id="medium-posts-widget" data-medium-rss={mediumRSSFeedLink}></div> */}
      {/* <div>request: {requestSuccess.toString()}</div> */}
      {requestSuccess && mediumPosts.map((item, idx) => <Articles content={item} id={idx} key={`article_${idx}`} inScreen={inScreen} /> )}
      {mediumPosts.length > 0 && <ShareBar title={mediumPosts[currentArticle].title} link={mediumPosts[currentArticle].link} className="desktop"/>}
      
    </Layout>
  )
}

export default SecondPage
