import React, { useState, useRef, useEffect } from 'react';
import {Column, Row} from "../styles/grid"
import {H1, NavCopy} from "../styles/typography/typography"
import {ArticleBlock, Author, Kicker, Subtitle} from "./articleStyle"
import useOnScreen from "../hooks/useOnScreen"
import ShareBar from "./shareBar"
import colors from "../styles/vars/colors"

const Articles = ({content, inScreen, id}) => {
    const contentEl = useRef(null);
    const [kicker, setKicker] = useState('')
    const [subtitle, setSubtitle] = useState('')
   const isVisible = useOnScreen(contentEl)
   const colorBGs = [colors.background1, colors.background2, colors.background3, colors.lightpink]

    useEffect(()=>{
        let _k = contentEl.current.querySelector('em');
        if(_k){
            let _sub = contentEl.current.querySelectorAll('h4')[1];
            _sub.classList.add("hide");
            setKicker(_k.textContent); 
            setSubtitle(_sub.textContent); 
        }
    
           
    },[contentEl.current]);

        
    useEffect(()=>{
        inScreen(id, isVisible);
    },[isVisible]);


const tagList = content.categories.map((item, idx) => <li key={`list-${idx}`}><NavCopy>#{item}</NavCopy></li>)

return (
    <ArticleBlock flex_dir={'column'} bgColor={colorBGs[id]}>
        <Row full padding className="main">
            <Row pd={'70px 40px 0 0'} pd_m={'25px 0 0 0'}>
                <Column>
                    <Kicker>{kicker}</Kicker>
                    <H1 className="titles">{content.title}</H1>
                    <Subtitle>{subtitle}</Subtitle>
                    <Column className="authors">
                        <Author> {content.author} </Author>
                        <Author>{content.pubDate}</Author>
                    </Column>
                    <Column className="content">
                        <div ref={contentEl} dangerouslySetInnerHTML={{__html:content.content}}></div>
                    </Column>
                </Column>
            </Row>
            <Row full className="social">
                <ShareBar title={content.title} link={content.link} className="mobile"/>
            </Row>
        </Row>
        <Row full className="tags">
            <Row padding className="tags__list">
                { content.categories && tagList}
            </Row>
        </Row>     
    </ArticleBlock>
    )

}

export default Articles